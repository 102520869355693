<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header">Example Component</div>

                    <div class="card-body">
                    
                        <form @submit.prevent="handleLogin">
                        
                            <div class="row mb-3">
                                <label for="email" class="col-md-4 col-form-label text-md-end">Email</label>

                                <div class="col-md-6">
                                    <input id="email" type="email" class="form-control" v-model="email">
                                    <span class="alert-danger" role="alert" v-if="errors.email">{{ errors.email[0] }}</span>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <label for="password" class="col-md-4 col-form-label text-md-end">Password</label>

                                <div class="col-md-6">
                                    <input id="password" type="password" class="form-control" v-model="password">
                                    <span class="alert-danger" role="alert" v-if="errors.password">{{ errors.password[0] }}</span>
                                
                                </div>
                            </div>

                            <div class="row mb-0">
                                <div class="col-md-8 offset-md-4">
                                    <button type="submit" class="btn btn-primary">
                                        Valider
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import axios from 'axios';
    import config from './../config.js';


    export default {

       data() {
           return {
                email: '',
                password: '',
                errors: {},
                
           }
           
       },

        methods: {

           async handleLogin() {
                const data = {
                    grant_type:  config.GRANT_TYPE,
                    client_id:  config.CLIENT_ID,
                    client_secret: config.CLIENT_SECRET,
                    username: this.email,
                    password: this.password,
                }
            
                try {
                    const authorize = await axios.post('oauth/token', data);
                    localStorage.setItem('token', `${authorize.data.token_type} ${ authorize.data.access_token }`);

                    const response = await axios.get('api/user')
        
                    await this.$store.dispatch('setUser', response.data)
                
                    await this.$router.push('/')

                } catch (error) {
                    // this.errors = error.response.data.errors
                    console.log('error', console.log(error.response))
                }
            }
        }
    }
</script>
